import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider, Typography, Box } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import TransactionalBehavior from "./scenes/TransactionalBehavior";
import KycDetails from "./scenes/KycDetails";
import KycList from "./scenes/KycList";
import Dashboard from "./scenes/Dashboard";
import Login from "./scenes/Login";
import Transactions from "./scenes/Transactions";
import Thresholds from "./scenes/Thresholds";
import Kyc from "./scenes/Kyc";
import Analysis from "./scenes/Analysis";
import {
  ConvertToNumber,
  groupTransactionsBy,
  normalizeDataSet,
  formatDate,
} from "./utils";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Context } from "./context";
import { getTransactions, getAggregations } from "./data/Controller";
import { DB as db } from "./config/constants";
import { RequireAuth, useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { TopMessageBar } from "./components/TopMessageBar/TopMessageBar";
import Help from "./scenes/Help";
import KycAggregation from "./scenes/KycAggregation";
import CtrAggregation from "./scenes/CtrAggregation";
import CrossSiteAutnetication from "./scenes/CrossSiteAuthentication";
// import RecordKeeping from "./scenes/recordKeeping";
// import ComplianceOficer from "./scenes/complianceOficer";
// import AmlBsaProgram from "./scenes/amlBsaProgram";
// import FincenRegistration from "./scenes/fincenRegistration";
// import IndependentReview from "./scenes/independentReview";
// import RiskAssessment from "./scenes/riskAssessment";
// import Ofac from "./scenes/ofac";
// import Findings from "./scenes/findings";

function App() {
  //Calculating period initial dates
  let prevYear = new Date();
  prevYear.setFullYear(prevYear.getFullYear() - 1);
  prevYear = `${prevYear.getFullYear()}-01-01`;

  const auth = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const [period, setPeriod] = useState({
    from: formatDate(prevYear),
    to: formatDate(new Date()),
  });
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [agency, setAgency] = useState({
    current: [],
    list: [],
  });
  const [rawTransactions, setRawTransactions] = useState([]);
  const [threshold, setThreshold] = useState([]);
  const [aggregation, setAggregation] = useState([]);

  const [loaderMessages, setLoaderMessages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [periodFrom, setPeriodFrom] = useState("");
  const [periodTo, setPeriodTo] = useState("");
  const [transactionsByCountry, setTransactionsByCountry] = useState([]);
  const [transactionsByCountryMax, setTransactionsByCountryMax] = useState(0);
  const [transactionsByLicense, setTransactionsByLicense] = useState([]);
  const [transactionsByLicenseMax, setTransactionsByLicenseMax] = useState(0);
  const [transactionsBySender, setTransactionsBySender] = useState([]);
  const [transactionsBySenderMax, setTransactionsBySenderMax] = useState(0);
  const [credentials, setCredentials] = useState({});
  const [authId, setAuthId] = useState(null); // eslint-disable-line no-unused-vars

  const getTransactionsFromServer = async (msg) => {
    setLoader(true);
    if (isAuthenticated()) {
      setAuthId(auth().agent_id);
      const from = period.from;
      const to = period.to;
      // Getting transactions from server
      const transactions = await getTransactions(
        auth().agent_id,
        agency.current,
        from,
        to
      );
      setRawTransactions(normalizeDataSet(transactions));
      setThreshold(normalizeDataSet(transactions));

      // Getting Aggregations from server
      const aggregations = await getAggregations(
        auth().agent_id,
        agency.current,
        from,
        to
      );

      setAggregation(aggregations);

      setLoader(false);
    }
    setLoader(false);
  };

  useEffect(
    () => {
      if (rawTransactions.length > 0) {
        // Grouping transactions by country
        const [data, max] = groupTransactionsBy(rawTransactions, "country");
        setTransactionsByCountry(data);
        setTransactionsByCountryMax(max);
        // Grouping transactions by license
        const [licenseData, licenseMax] = groupTransactionsBy(
          rawTransactions,
          "license"
        );
        setTransactionsByLicense(licenseData);
        setTransactionsByLicenseMax(licenseMax);
        // Grouping transactions by sender
        const [senderData, senderMax] = groupTransactionsBy(
          rawTransactions,
          "sender"
        );
        setTransactionsBySender(senderData);
        setTransactionsBySenderMax(senderMax);

        //Calculating list of agencies
        if (agency.list.length < 1) {
          const agencySet = new Set();

          for (let t of rawTransactions) {
            agencySet.add(t.agency);
          }
          const agencyList = Array.from(agencySet.values());
          setAgency({
            ...agency,
            list: agencyList,
          });
        }
      }
    },
    [rawTransactions] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    getTransactionsFromServer();
  }, [period, agency]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Context.Provider
      value={{
        loader,
        setLoader,
        period,
        periodFrom,
        setPeriodFrom,
        periodTo,
        setPeriodTo,
      }}
    >
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {db === "test_agents" && (
            <TopMessageBar message="DEVELOPMENT ENVIRONMENT" />
          )}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <Box>
              <CircularProgress color="inherit" />
              {["Loading..."].map((msg, key) => (
                <Typography key={key} color="white">
                  {msg}
                </Typography>
              ))}
            </Box>
          </Backdrop>
          <div className="app">
            {isAuthenticated() && (
              <Sidebar
                isSidebar={isSidebar}
                rawTransactions={rawTransactions}
                auth={auth}
                setLoader={setLoader}
                credentials={credentials}
                setRawTransactions={setRawTransactions}
                period={period}
              />
            )}

            <main className="content">
              {isAuthenticated() && (
                <Topbar
                  period={period}
                  agency={agency}
                  setAgency={setAgency}
                  setPeriod={setPeriod}
                  setIsSidebar={setIsSidebar}
                  setCredentials={setCredentials}
                  setRawTransactions={setRawTransactions}
                  setAuthId={setAuthId}
                  setLoader={setLoader}
                  credentials={credentials}
                  auth={auth}
                  transactions={rawTransactions?.length}
                />
              )}

              <Routes>
                <Route
                  path="/"
                  element={
                    <RequireAuth loginPath="/login">
                      <Dashboard
                        aggregation={aggregation}
                        transactionsByCountry={transactionsByCountry}
                        setTransactionsByCountry={setTransactionsByCountry}
                        transactionsByCountryMax={transactionsByCountryMax}
                        setTransactionsByCountryMax={
                          setTransactionsByCountryMax
                        }
                        transactionsByLicense={transactionsByLicense}
                        setTransactionsByLicense={setTransactionsByLicense}
                        transactionsByLicenseMax={transactionsByLicenseMax}
                        setTransactionsByLicenseMax={
                          setTransactionsByLicenseMax
                        }
                        transactionsBySender={transactionsBySender}
                        setTransactionsBySender={setTransactionsBySender}
                        transactionsBySenderMax={transactionsBySenderMax}
                        setTransactionsBySenderMax={setTransactionsBySenderMax}
                        findings={aggregation}
                        transactions={rawTransactions}
                        rawTransactions={rawTransactions}
                        setRawTransactions={setRawTransactions}
                        setAggregation={setAggregation}
                        setLoaderMessages={setLoaderMessages}
                        loaderMessages={loaderMessages}
                        credentials={credentials}
                        auth={auth}
                      />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/transactions"
                  element={
                    <RequireAuth loginPath="/login">
                      <Transactions rawTransactions={rawTransactions} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <Login
                      credentials={credentials}
                      setCredentials={setCredentials}
                    />
                  }
                />

                <Route
                  path="/authentication/:id/:role/:email"
                  element={<CrossSiteAutnetication
                    credentials={credentials}
                    setCredentials={setCredentials}
                  />}
                />

                <Route
                  path="/thresholds"
                  element={
                    <RequireAuth loginPath="/login">
                      <Thresholds
                        threshold={threshold}
                        setThreshold={setThreshold}
                        rawTransactions={rawTransactions}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/transactional-behavior"
                  element={
                    <RequireAuth loginPath="/login">
                      <TransactionalBehavior
                        rawTransactions={rawTransactions}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/know-your-customer/:id/:customer/:kycId"
                  element={
                    <RequireAuth loginPath="/login">
                      <Kyc branches={agency.list} auth={auth} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/know-your-customer/"
                  element={
                    <RequireAuth loginPath="/login">
                      <Kyc branches={agency.list} auth={auth} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/know-your-customer/:id/:customer"
                  element={
                    <RequireAuth loginPath="/login">
                      <Kyc branches={agency.list} auth={auth} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/know-your-customer-details"
                  element={
                    <RequireAuth loginPath="/login">
                      <KycDetails
                        rawTransactions={rawTransactions}
                        getTransactionsFromServer={getTransactionsFromServer}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/know-your-customer-list"
                  element={
                    <RequireAuth loginPath="/login">
                      <KycList rawTransactions={rawTransactions} auth={auth} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/kyc-aggregation"
                  element={
                    <RequireAuth loginPath="/login">
                      <KycAggregation
                        auth={auth}
                        aggregation={aggregation}
                        rawTransactions={rawTransactions}
                        setAggregation={setAggregation}
                        setPeriodFrom={setPeriodFrom}
                        setPeriodTo={setPeriodTo}
                        setLoader={setLoader}
                        setLoaderMessages={setLoaderMessages}
                        loaderMessages={loaderMessages}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/ctr-aggregation"
                  element={
                    <RequireAuth loginPath="/login">
                      <CtrAggregation
                        auth={auth}
                        aggregation={aggregation}
                        rawTransactions={rawTransactions}
                        setAggregation={setAggregation}
                        setPeriodFrom={setPeriodFrom}
                        setPeriodTo={setPeriodTo}
                        setLoader={setLoader}
                        setLoaderMessages={setLoaderMessages}
                        loaderMessages={loaderMessages}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/help"
                  element={
                    <Help
                      transactionsByCountry={transactionsByCountry}
                      setTransactionsByCountry={setTransactionsByCountry}
                      transactionsByCountryMax={transactionsByCountryMax}
                      setTransactionsByCountryMax={setTransactionsByCountryMax}
                      transactionsByLicense={transactionsByLicense}
                      setTransactionsByLicense={setTransactionsByLicense}
                      transactionsByLicenseMax={transactionsByLicenseMax}
                      setTransactionsByLicenseMax={setTransactionsByLicenseMax}
                      transactionsBySender={transactionsBySender}
                      setTransactionsBySender={setTransactionsBySender}
                      transactionsBySenderMax={transactionsBySenderMax}
                      setTransactionsBySenderMax={setTransactionsBySenderMax}
                      findings={aggregation}
                      transactions={rawTransactions}
                      rawTransactions={rawTransactions}
                      setRawTransactions={setRawTransactions}
                      aggregation={aggregation}
                      setAggregation={setAggregation}
                      setLoaderMessages={setLoaderMessages}
                      loaderMessages={loaderMessages}
                      credentials={credentials}
                      auth={auth}
                    />
                  }
                />
                {/* <Route path="/record-keeping" element={<RecordKeeping />} />
                <Route path="/compliance-oficer" element={<ComplianceOficer />} />
                <Route path="/aml-bsa-program" element={<AmlBsaProgram />} />
                <Route
                  path="/fincen-registration"
                  element={<FincenRegistration />}
                />
                <Route
                  path="/independent-reviews"
                  element={<IndependentReview />}
                />
                <Route path="/risk-assessments" element={<RiskAssessment />} />
                <Route path="/ofac" element={<Ofac />} />
                <Route path="/findings" element={<Findings />} />
                 */}
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Context.Provider>
  );
}

export default App;
