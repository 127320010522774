export { groupAmountsBy } from "./groupAmountsBy";

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} str - The string to capitalize.
 * @returns {string} - The input string with the first letter capitalized.
 */
const capitalize = (str) => {
  if (!str) return "";

  const words = str.replace(",", "").split(" ");
  const result = [];

  for (let word of words) {
    const lower = word.toLowerCase();

    if (word) result.push(word.charAt(0).toUpperCase() + lower.slice(1));
  }

  return result.join(" ");
};

const formatPhoneNumber = (phoneNumber) => {
  // Remove non-digit characters from the phone number
  if (!phoneNumber) return "";
  const digitsOnly = phoneNumber.replace(/\D/g, "");

  // Apply the desired format using regular expressions
  const formattedNumber = digitsOnly.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );

  return formattedNumber;
};

/**
 * Converts a string containing a numeric value with commas and/or a currency symbol to a number.
 *
 * @param {string} str - The string to convert to a number.
 * @returns {number} - The numeric value of the input string.
 * If the input string cannot be converted to a number, NaN will be returned.
 */
const ConvertToNumber = (str) =>
  typeof str === "string"
    ? parseFloat(str.replace("$", "").split(",").join(""))
    : str;

/**
 * Filters an array of transaction data by status and returns the filtered data.
 *
 * @param {Array<Object>} data - An array of objects containing transaction data.
 * @param {string} status - The status to filter by.
 * @returns {Array<Object>} - An array of objects containing transaction data that match the specified status.
 */
const getTransactionsStatus = (data = [], status) => {
  return data.length ? data.filter((d) => d.status === status) : [];
};

/**
 * Normalizes the raw transaction data and returns the normalized data set.
 *
 * @param {Array<Object>} rawTransactions - An array of objects containing raw transaction data.
 * @returns {Array<Object>} - An array of objects containing normalized transaction data.
 * If the input array is empty, an empty array will be returned.
 */
const normalizeDataSet = (rawTransactions) => {
  if (rawTransactions.length > 0) {
    const dataSet = rawTransactions.map(
      ({
        receipt = "",
        sender = "",
        receiver = "",
        date = "",
        amount = 0,
        country = "",
        agency = "",
        license = "",
        status = "",
        batch_date = "",
        owner = "",
      }) => ({
        sender: capitalize(sender.replace(/,/g, "")),
        id: receipt,
        receiver: capitalize(receiver.replace(/,/g, "")),
        date: formatDate(date),
        agency: agency,
        country: country,
        license: license,
        status: status,
        amount: ConvertToNumber(amount),
        batch_date,
        owner,
      })
    );
    return dataSet;
  }
  return [];
};

/**
 * Formats a number as a US dollar currency amount.
 *
 * @param {number} number - The number to format as a currency amount.
 * @returns {string} - A string representation of the input number formatted as a US dollar currency amount.
 */
const formatCurrency = (number) => {

  number = number ?? 0;
  return parseFloat(number).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })
}

/**
 * Calculates the earliest and latest dates in an array of objects with a `date` property.
 * @param {Object[]} data - The array of objects to calculate the period from.
 * @param {string} data[].date - The date string in the format "YYYY-MM-DD".
 * @returns {Object} An object with `from` and `to` properties representing the earliest and latest dates, respectively.
 * @returns {Date} An ISO-8601 formatted date string representing the earliest date in the period.
 * @returns {Date} An ISO-8601 formatted date string representing the latest date in the period.
 */

const calculatePeriod = (data) => {
  const { earliestDate, latestDate } = data.reduce(
    (acc, curr) => {
      const currDate = new Date(curr.date);
      if (currDate < acc.earliestDate) {
        acc.earliestDate = currDate;
      }
      if (currDate > acc.latestDate) {
        acc.latestDate = currDate;
      }
      return acc;
    },
    {
      earliestDate: new Date(data[0].date),
      latestDate: new Date(data[0].date),
    }
  );

  return {
    from: earliestDate,
    to: latestDate,
  };
};

/**
Groups transactions by countries and returns data for chart display.
@param {Array} rawTransactions - An array of transaction objects containing at least a "license" property.
@returns {Array} An array of objects with "name" and "value" properties, representing the grouped transaction data.
 */
const groupTransactionsBy = (rawTransactions, k) => {
  // Define the data you want to display in the chart
  const data = [];
  let max = 0;
  // Group by countries
  const transactionsByCountries = [];

  for (let transaction of rawTransactions) {
    let key = transaction[k];
    if (!key) key = "Others";

    if (!transactionsByCountries[key]) transactionsByCountries[key] = 0;
    transactionsByCountries[key]++;
  }

  for (let key in transactionsByCountries) {
    const value = transactionsByCountries[key];

    if (value > max) max = value;
    data.push({
      name: key,
      value: value,
    });
  }

  return [data, max];
};

/**
Groups transactions by countries and returns data for chart display.
@param {Array} rawTransactions - An array of transaction objects containing at least a "license" property.
@returns {Array} An array of objects with "name" and "value" properties, representing the grouped transaction data.
 */
const getTop10 = (rawTransactions, k) => {
  // Define the data you want to display in the chart
  const data = [];

  // Group by countries
  const transactionsByCountries = [];

  for (let transaction of rawTransactions) {
    let key = transaction[k];
    if (!key) key = "Others";

    if (!transactionsByCountries[key])
      transactionsByCountries[key] = {
        amount: 0,
        frequency: 0,
        id: 0,
        partner: new Set(),
      };
    transactionsByCountries[key]["id"] = transaction.id;
    transactionsByCountries[key]["amount"] += transaction.amount;
    transactionsByCountries[key]["frequency"]++;
    if (k === "receiver") {
      transactionsByCountries[key]["partner"].add(transaction.sender);
    } else {
      transactionsByCountries[key]["partner"].add(transaction.receiver);
    }
  }

  for (let key in transactionsByCountries) {
    const t = transactionsByCountries[key];

    data.push({
      id: t.id,
      name: key,
      amount: t.amount,
      frequency: t.frequency,
      partner: Array.from(t.partner).length,
      avg: (t.amount / t.frequency).toFixed(2),
    });
  }

  const sorted = data.sort((a, b) => b.amount - a.amount);
  return sorted;
};

/**
Fetch Number Of Top the Sender & Receivers Group Transactions 
@param {Array} rawTransactions - An array of transaction objects containing at least a "license" property.
@param {String} k - sender or receiver.
@param {Number} limit - number of records.
@param {String} sortBy - sort data by specific key name.
@returns {Array} An array of objects with "name" and "value" properties, representing the grouped transaction data.
*/
const getTopSendersAndReceivers = (rawTransactions, k, limit = 10, sortBy = null) => {
  // Define the data you want to display in the chart
  const data = [];

  // Group by countries
  const transactionsByCountries = [];

  for (let transaction of rawTransactions) {
    let key = transaction[k];
    if (!key) key = "Others";

    if (!transactionsByCountries[key])
      transactionsByCountries[key] = {
        amount: 0,
        frequency: 0,
        id: 0,
        partner: new Set(),
      };
    transactionsByCountries[key]["id"] = transaction.id;
    transactionsByCountries[key]["amount"] += transaction.amount;
    transactionsByCountries[key]["frequency"]++;
    if (k === "receiver") {
      transactionsByCountries[key]["partner"].add(transaction.sender);
    } else {
      transactionsByCountries[key]["partner"].add(transaction.receiver);
    }
  }

  for (let key in transactionsByCountries) {
    const t = transactionsByCountries[key];

    data.push({
      id: t.id,
      name: key,
      amount: t.amount,
      frequency: t.frequency,
      partner: Array.from(t.partner).length,
      avg: (t.amount / t.frequency).toFixed(2),
    });
  }

  let sorted = [];
  if (sortBy && sortBy != null) {
    sorted = data.sort((a, b) => b[sortBy] - a[sortBy]);
  } else {
    sorted = data.sort((a, b) => b.amount - a.amount);
  }

  const topTransactions = (sorted && sorted.length > 0) ? sorted.slice(0, limit) : [];
  return topTransactions;
};

/**
Fetch records from Minimum to Maximum Amount Of Transactions
@param {Array} rawTransactions - An array of transaction objects containing at least a "license" property.
@param {Number} minAmount - minimum amount.
@param {Number} maxAmount - maxmimum amount.
@returns {Array} An array of objects with "name" and "value" properties, representing the grouped transaction data.
*/
const getMinAndMaxTransactions = (rawTransactions, minAmount, maxAmount, limit = 0) => {
  let data = [];
  minAmount = parseInt(minAmount);
  maxAmount = parseInt(maxAmount);

  if (rawTransactions && rawTransactions.length > 0) {
    data = rawTransactions.filter((element) => element.amount >= minAmount && element.amount <= maxAmount);

    if (limit && limit >= 0) {
      data = data.splice(0, limit);
    }
  }

  data = (data && data.length > 0) ? data.sort((a, b) => a.amount - b.amount) : [];

  data = data.map(element => {
    let trxElem = {
      name: element.sender,
      date: element.date,
    }

    let custTrx = rawTransactions.filter((cust) => cust.sender === element.sender).map(trsx => trsx.amount);
    let totalTransactions = custTrx.length;
    let totalCustTrxAmount = custTrx.reduce((a, b) => a + b, 0);
    trxElem.avg = totalCustTrxAmount / totalTransactions;

    return trxElem;
  });

  return data;
}

/**
Fetch records from Minimum to Maximum Amount Of Transactions
@param {Array} rawTransactions - An array of transaction objects containing at least a "license" property.
@param {Number} minAmount - minimum amount.
@param {Number} maxAmount - maxmimum amount.
@returns {Array} An array of objects with "name" and "value" properties, representing the grouped transaction data.
*/
const getSingleTransactionsMoreThan10kData = (rawTransactions, maxAmount, limit = 0) => {
  let data = [];
  maxAmount = parseInt(maxAmount);

  if (rawTransactions && rawTransactions.length > 0) {
    data = rawTransactions.filter((element) => element.amount >= maxAmount);
    data = (data && data.length > 0) ? data.sort((a, b) => a.amount - b.amount) : [];

    if (limit && limit >= 0) {
      data = data.splice(0, limit);
    }
  }

  return data;
}

/**
Fetch records from Minimum to Maximum Amount Of Transactions
@param {Array} rawTransactions - An array of transaction objects containing at least a "license" property.
@param {Number} minAmount - minimum amount.
@param {Number} maxAmount - maxmimum amount.
@returns {Array} An array of objects with "name" and "value" properties, representing the grouped transaction data.
*/
const getAggregatedTransactions = (rawTransactions, minAmount, maxAmount, limit = 0) => {
  let data = [];
  minAmount = parseInt(minAmount);
  maxAmount = parseInt(maxAmount);

  const senders = [...new Map(rawTransactions.map(transaction => [transaction.sender, transaction])).keys()];
  const sendersTransactions = senders.map(sender => {
    let transactions = rawTransactions.filter((cust) => cust.sender === sender);
    let totalSentAmount = transactions.reduce((a, b) => a + b.amount, 0);

    return { transactions, totalSentAmount };
  });

  if (sendersTransactions && sendersTransactions.length > 0) {
    data = sendersTransactions.filter((element) => element.totalSentAmount >= minAmount && element.totalSentAmount <= maxAmount);
    data = data.sort((a, b) => a.amount - b.amount);

    if (limit && limit >= 0) {
      data = data.splice(0, limit);
    }
  }

  console.log("data ==> ", data);

  return data;
}

const formatDate = (d) => {
  if (!d) return "";
  let date = new Date(d);
  let formattedDate = date.toISOString().slice(0, 10);
  return formattedDate;
};

function addMonths(date, months) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
}

const calculateTransactionThresholds = (rawTransactions) => {
  const thresholds = {
    "Between $1 and $999": [],
    "Between $1,000 and $2,999": [],
    "Between $3,000 and $9,999": [],
    "Greater than or equal to $10,000": [],
  };
  const tableData = [];

  for (let t of rawTransactions) {
    if (t.amount >= 1 && t.amount <= 999)
      thresholds["Between $1 and $999"].push(t);
    if (t.amount >= 1000 && t.amount <= 2999)
      thresholds["Between $1,000 and $2,999"].push(t);
    if (t.amount >= 3000 && t.amount <= 9999)
      thresholds["Between $3,000 and $9,999"].push(t);
    if (t.amount >= 10000)
      thresholds["Greater than or equal to $10,000"].push(t);
  }

  for (let key in thresholds) {
    const frequency = thresholds[key].length;
    const avg = (frequency / rawTransactions.length) * 100;
    const category = key;
    if (thresholds[key][0]) {
      tableData.push({
        id: thresholds[key][0].id,
        category,
        frequency,
        avg,
      });
    }
  }

  return tableData;
};

const generateKey = (len = 8) => {
  let key = "";
  const d = new Date();

  for (let i = 0; i < len; i++) {
    key += Math.floor(Math.random() * 10);
  }

  return (
    key +
    d.getFullYear() +
    d.getUTCFullYear() +
    d.getUTCMonth() +
    d.getUTCDay() +
    d.getMilliseconds()
  );
};

export {
  calculateTransactionThresholds,
  formatDate,
  calculatePeriod,
  capitalize,
  ConvertToNumber,
  getTransactionsStatus,
  normalizeDataSet,
  formatCurrency,
  groupTransactionsBy,
  getTop10,
  formatPhoneNumber,
  generateKey,
  addMonths,
  getTopSendersAndReceivers,
  getMinAndMaxTransactions,
  getAggregatedTransactions,
  getSingleTransactionsMoreThan10kData
};
