import * as XLSX from "xlsx";
import { ConvertToNumber } from "../utils";
import { insertTransaction } from "../data/Controller";

export const fileReader = async (
  file,
  setRawTransactions,
  credentials,
  authId,
  setLoader
) => {
  const push = async (content) => {
    const results = await insertTransaction(content, setRawTransactions);
    if (results) setRawTransactions(results);
    setLoader(false);
  };

  const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArr = e.target.result;

      const wb = XLSX.read(bufferArr, { type: "buffer" });
      const wsName = wb.SheetNames[0];
      const ws = wb.Sheets[wsName];

      const data = XLSX.utils.sheet_to_json(ws, {
        raw: false,
        rawNumbers: false,
        dateNF: "yyyy-mm-dd",
      });

      resolve(data);
    };

    fileReader.onerror = (e) => reject(e);
  });

  promise.then((d) => {
    const data = [];
    for (let transaction of d) {
      const record = transaction;
      record.agent_id = authId().agent_id;
      record.date = new Date(record.date);
      record._id = authId().agent_id + record.receipt;
      record.id = authId().agent_id + record.receipt;
      record.amount = ConvertToNumber(record.amount);
      record.owner = authId().email;
      record.batch_date = new Date();
      data.push(record);
    }

    push(data).catch((error) => {
      console.error("The Error:", error);
    });
  });
};
