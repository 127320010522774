import { useRef, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Button,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MobiledataOffIcon from "@mui/icons-material/MobiledataOff";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import LogoLight from "../../assets/images/msb-control-logo-light.svg";
import LogoDark from "../../assets/images/msb-control-logo-dark.svg";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { fileReader } from "../../data/FileReader";
import {
  calculateTransactionThresholds,
  getMinAndMaxTransactions,
  getSingleTransactionsMoreThan10kData,
  getTopSendersAndReceivers,
  groupAmountsBy,
} from "../../utils";
import { downloadMSBReport } from "../../data/Controller";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({
  auth,
  setLoader,
  credentials,
  setRawTransactions,
  rawTransactions,
  period,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDownloadingPDF, setIsDonwloadingPDF] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const handlePrintDashboardReport = async () => {
    try {
      setIsDonwloadingPDF(true);

      let requestData = {};
      let startDate = period.from;
      let endDate = period.to;

      // Get Principal Matrics Data
      const principalMatricsData = await groupAmountsBy(
        rawTransactions,
        "license"
      );
      requestData.principalMatricsData = principalMatricsData;

      // Get Risk Consideration Data
      const riskConsiderationData = await calculateTransactionThresholds(
        rawTransactions
      );
      requestData.riskConsiderationData = riskConsiderationData;

      // Fetch $999 Raw From The Risk Consideration Data
      const riskConsideration = await riskConsiderationData[0];
      requestData.riskConsideration = riskConsideration;

      // Get Amount Spent By Countries Data
      const amountSpentByCountries = await groupAmountsBy(
        rawTransactions,
        "country"
      );
      requestData.amountSpentByCountries = amountSpentByCountries;

      // Get Top 20 Senders
      const top20Senders = await getTopSendersAndReceivers(
        rawTransactions,
        "sender",
        20
      );
      requestData.top20Senders = top20Senders;

      // Get Top 20 Beneficiary Matrics
      const top20Beneficiary = await getTopSendersAndReceivers(
        rawTransactions,
        "receiver",
        20
      );
      requestData.top20Beneficiary = top20Beneficiary;

      // Top Senders with Higher Number of Beneficiaries
      const topSendersHighNoOfReceivers = await getTopSendersAndReceivers(
        rawTransactions,
        "sender",
        15,
        "partner"
      );
      requestData.topSendersHighNoOfReceivers = topSendersHighNoOfReceivers;

      // Single Transactions for more than US$3,000 and less than US$10,000
      const minAndMaxTransactionsData = await getMinAndMaxTransactions(
        rawTransactions,
        3000,
        10000,
        20
      );
      requestData.minAndMaxTransactionsData = minAndMaxTransactionsData;

      // Single transactions for more than US$10,000
      const singleTransactionsMoreThan10kData =
        await getSingleTransactionsMoreThan10kData(rawTransactions, 10000, 20);
      requestData.singleTransactionsMoreThan10kData =
        singleTransactionsMoreThan10kData;

      // Generate JSON File
      const jsonFile = await createJsonFile(requestData);

      // Prepare Form Data
      let formData = new FormData();
      formData.append("file", jsonFile);
      formData.append("name", "MSB Control - Report");
      formData.append("startdate", startDate);
      formData.append("enddate", endDate);

      // Calling WP API for download PDF report
      const blob = await downloadMSBReport(formData);

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `msb-console-report-${new Date().getTime()}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      setIsDonwloadingPDF(false);
    } catch (error) {
      console.error("Error:::: ", error);
      // setIsDonwloadingPDF(false);
    }
  };

  const createJsonFile = async (data, fileName = "report-data.json") => {
    const blob = new Blob([JSON.stringify(data, null, 1)], {
      type: "application/json",
    });
    return new File([blob], fileName, { type: "application/json" });
  };

  return (
    <Box
      className="not-for-report"
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                {/* <Typography variant="h4" color={colors.grey[100]}>
                  MSB CONTROL
                </Typography> */}
                <img
                  src={theme.palette.mode === "light" ? LogoDark : LogoLight}
                  width={100}
                  style={{ margin: "20px  auto 40px", display: "block" }}
                  alt="MSB-Control"
                />
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                {/* <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                /> */}
              </Box>
              <Box textAlign="center">
                {/* <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {auth().agency}
                </Typography> */}
                {/* <Typography variant="h5" color={colors.greenAccent[500]}>
                  {auth().email}
                </Typography> */}
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {rawTransactions?.length > 0 ? (
              <Box>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Tools
                </Typography>
                <Item
                  title="Transactions Log"
                  to="/transactions"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Transactional Behavior"
                  to="/transactional-behavior"
                  icon={<SensorOccupiedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Risk Concentration"
                  to="/thresholds"
                  icon={<ScatterPlotIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="KYC Aggregation"
                  to="/kyc-aggregation"
                  icon={<MobiledataOffIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="CTR Aggregation"
                  to="/ctr-aggregation"
                  icon={<MobiledataOffIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                {/* <Item
                  title="KYC form"
                  to="/know-your-customer"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}

                <Item
                  title="KYC Records"
                  to="/know-your-customer-list"
                  icon={<VerifiedUserIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Files
                </Typography>
                <MenuItem
                  style={{
                    color: colors.grey[100],
                    marginTop: 10,
                  }}
                  icon={<DownloadOutlinedIcon />}
                >
                  <Typography>Download Template</Typography>
                  <a href="https://app.msbcontrol.com/assets/template.xls" />
                </MenuItem>
                <MenuItem
                  style={{
                    color: colors.grey[100],
                    marginTop: 10,
                  }}
                  icon={<UploadOutlinedIcon />}
                >
                  <Button
                    component="label"
                    sx={{
                      color: colors.grey[100],
                      textTransform: "none",
                      padding: 0,
                    }}
                  >
                    <Typography>Upload Transactions</Typography>
                    <input
                      hidden
                      type="file"
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        setLoader(true);
                        fileReader(
                          file,
                          setRawTransactions,
                          credentials,
                          auth,
                          setLoader
                        );
                      }}
                    />
                  </Button>
                </MenuItem>

                {/* Download Dashboard Report */}
                <MenuItem
                  style={{
                    color: colors.grey[100],
                    marginTop: 10,
                  }}
                  icon={
                    isDownloadingPDF ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      <DownloadOutlinedIcon />
                    )
                  }
                >
                  <Button
                    component="label"
                    sx={{
                      color: colors.grey[100],
                      textTransform: "none",
                      padding: 0,
                    }}
                    disabled={isDownloadingPDF}
                    onClick={handlePrintDashboardReport}
                  >
                    <Typography>Download Reports</Typography>
                  </Button>
                </MenuItem>
                {/* <div style={{ display: "none" }}>
                  <DashboardReportDocument rawTransactions={rawTransactions} ref={reportTemplateRef} />
                </div> */}

                {/* <Item
                  title="Help"
                  to="/help"
                  icon={<HelpOutlineOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
                {/* 
                <Item
                  title="Record Keeping"
                  to="/record-keeping-requirements"
                  icon={<RecentActorsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  tools
                </Typography>
                <Item
                  title="Compliance Officer"
                  to="/compliance-officer-designation"
                  icon={<PersonOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="AML/BSA Program"
                  to="/aml-bsa-program"
                  icon={<GradingIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="FINCEN Registration"
                  to="/fincen-registration"
                  icon={<VerifiedUserIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Independent Review"
                  to="/independent-review"
                  icon={<PlaylistAddCheckCircleIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Risk Assessment"
                  to="/risk-assessment"
                  icon={<NearbyErrorIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="OFAC"
                  to="/ofac"
                  icon={<SensorOccupiedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Findings"
                  to="/findings-recomendations"
                  icon={<WifiFindIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Help"
                  to="/help"
                  icon={<HelpOutlineOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}

                {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            > */}
                {/* Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
              </Box>
            ) : (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Files
                </Typography>
                <MenuItem
                  style={{
                    color: colors.grey[100],
                    marginTop: 10,
                  }}
                  icon={<UploadOutlinedIcon />}
                >
                  <Button
                    component="label"
                    sx={{
                      color: colors.grey[100],
                      textTransform: "none",
                      padding: 0,
                    }}
                  >
                    <Typography>Upload Transactions</Typography>
                    <input
                      hidden
                      type="file"
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        setLoader(true);
                        fileReader(
                          file,
                          setRawTransactions,
                          credentials,
                          auth,
                          setLoader
                        );
                      }}
                    />
                  </Button>
                </MenuItem>
              </>
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
